<template>
   <v-form ref="questionnaire-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Questionnaire setting</h3>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.title" label="Title" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.min" label="Min score" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.max" label="Max score" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8" large :disabled="isFetching" type="submit">Save</v-btn>
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
   name: 'QuestionnaireSetting',
   components: {
   FormControl
   },
   data() {
      return {
         isNew: true,
         data: {
            id: _.toNumber(this.$route.params.id),
            title: '',
            min: 0,
            max: 0,
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),

      async initData() {
         console.log('initData', this.data.id)
         const resp = await this.sendRequest({ type: 'cms_get_questionnaire_setting', data: { page: 0, limit: 1 } })
         if (!resp) return
         const data = _.head(resp.data)
         if (data) {
            this.isNew = false
            this.data = {
               ...this.data,
               id: data.id,
               title: data.title,
               min: data.min_score,
               max: data.max_score,
            }
         }
      },
      async formSubmit() {
         console.log('save', this.isNew)
         const isValid = this.$refs['questionnaire-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp,
            })
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            status: 'active',
            data_status: 'active',
            title: this.data.title,
            min_score: _.toNumber(this.data.min),
            max_score:  _.toNumber(this.data.max),
         };
         return await this.sendRequest({ type: 'add_questionnaire_setting', data: formdata })
      },
      async updateItem() {
         console.log('update');
         const formdata = {
            data_type: 'questionnaire_setting_data',
            id: this.data.id,
            title: this.data.title,
            min_score: _.toNumber(this.data.min),
            max_score:  _.toNumber(this.data.max),
         };
         return await this.sendRequest({ type: 'update_questionnaire_setting', data: formdata });
      },
   },
   async mounted() {
      await this.initData();
   }
}
</script>
